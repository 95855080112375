// @ts-check

import createCache from "@emotion/cache";
import {CacheProvider} from "@emotion/react";
import CssBaseline from "@mui/material/CssBaseline";
import {ThemeProvider} from "@mui/material/styles";
import {AdapterLuxon} from "@mui/x-date-pickers/AdapterLuxon";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import isEmpty from "lodash/isEmpty";
import React, {useEffect, useMemo, useState} from "react";
import {useAuth} from "react-oidc-context";
import {useDispatch, useSelector} from "react-redux";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import {TssCacheProvider} from "tss-react";

import config from "../../../config/config.json";
import {WS_ACTIONS, WS_EVENTS} from "../../../config/event_config";
import {initTranslations} from "../../../config/i18n";
import DateProvider from "../../contexts/dates/date_provider";
import {setupWebSocket, SocketContext} from "../../contexts/websocket/websocket";
import useInterval from "../../hooks/use_interval";
import usePrevious from "../../hooks/usePrevious";
import {setToken} from "../../middleware/axios.middelware";
import {AccessDeniedPage} from "../../pages/access_denied";
import Account from "../../pages/account/account_page";
import AdminDisciplinesPage from "../../pages/admin_disciplines/admin_disciplines_page";
import AdminUserPage from "../../pages/admin_user/admin_user_page";
import {AvailabilityPlannerPage} from "../../pages/availability_planner";
import DayView from "../../pages/day_view/day_view_page";
import {NotFoundPage} from "../../pages/not_found";
import {NotificationsPage} from "../../pages/notifications";
import OpBacklogPage from "../../pages/op_backlog/op_backlog_page";
import OpManagement from "../../pages/op_management/op_management";
import {loadStateAction} from "../../pages/op_management/op_management_actions";
import {RoomPlannerPage} from "../../pages/room_planner";
import {SurgeryAssignmentPage} from "../../pages/surgery_assignment/surgery_assignment_page";
import TimeslotsPage from "../../pages/timeslots/timeslots_page";
import {authUser, fetchServiceVersionAction, getUserStrategy, logoutAction, setRefreshTrigger} from "../../redux/actions/index";
import {
    selectAuthError,
    selectCurrentTimezone,
    selectCurrentUserRights,
    selectLanguage,
    selectLogoutStatus,
    selectServiceVersion
} from "../../redux/app_selectors";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import {setEvent} from "../../redux/events/event_actions";
import {isPending} from "../../redux/utils/status";
import {createTheme} from "../../theme";
import {getLocalStorageItem} from "../../utils/local_storage";
import logger from "../../utils/logger_pino";
import {PERMISSION, SecurityProvider} from "../../utils/security";
import ErrorBoundary from "../error_boundary/error_boundary";
import {loadFeSettingsAction} from "../fe_settings/fe_settings_actions";
import {selectPathTranslation, selectTheme} from "../fe_settings/fe_settings_selectors";
import InfoDialog from "../shared/info_dialog/info_dialog";
import Loading from "../shared/loading";
import ProtectedRoute from "./protected_route";

const router = createBrowserRouter(
    [
        {
            element: <ProtectedRoute />,
            children: [
                {
                    path: "/op-management/schedule-management",
                    element: <Navigate replace to="/op-management/schedule-management/backlog" />
                },
                {
                    path: "/op-management/schedule-management/canvas",
                    element: <OpManagement />
                },
                {
                    path: "/op-management/schedule-management/backlog",
                    element: <OpBacklogPage />
                },
                {
                    path: "/op-management/timeslot-planner",
                    element: <TimeslotsPage />
                },
                {
                    path: "/op-management/availability-planner",
                    element: <AvailabilityPlannerPage />
                },
                {
                    path: "/op-management/room-planner",
                    element: <RoomPlannerPage />
                },
                {
                    path: "/admin/users",
                    element: <AdminUserPage />
                },
                {
                    path: "/admin/disciplines",
                    element: <AdminDisciplinesPage />
                },
                {
                    path: "/admin/surgery-assignment",
                    element: <SurgeryAssignmentPage />
                }
            ]
        },
        {
            path: "/",
            element: <DayView />
        },
        {
            path: "/schedule/dayview",
            element: <DayView />
        },
        {
            path: "/notifications",
            element: <NotificationsPage />
        },
        {
            path: "/account",
            element: <Account />
        },
        {
            path: "/access-denied",
            element: <AccessDeniedPage />
        },
        {
            path: "/404",
            element: <NotFoundPage />
        }
    ]
    // {
    //     future: {
    //         v7_fetcherPersist: true
    //     }
    // }
);

/** @typedef {import("socket.io-client").Socket} Socket */

/**
 * Intentionally, we use the following cache for MUI and TSS.
 * @link https://docs.tss-react.dev/troubleshoot-migration-to-muiv5-with-tss
 */
const muiCache = createCache({
    key: "mui",
    prepend: true
});

const tssCache = createCache({
    key: "tss"
});

const App = () => {
    const dispatch = useDispatch();
    const auth = useAuth();

    // Redux
    const authError = useSelector(selectAuthError);
    const currentLanguage = useSelector(selectLanguage);
    const organizationId = useSelector(selectCurrentOrganizationId);
    const email = useSelector(selectCurrentUserEmail);
    const timezone = useSelector(selectCurrentTimezone);
    const theme = useSelector(selectTheme);
    const pathTranslation = useSelector(selectPathTranslation);
    const rights = useSelector(selectCurrentUserRights);
    const serviceVersion = useSelector(selectServiceVersion);
    const previousServiceVersion = usePrevious(serviceVersion);
    const logoutStatus = useSelector(selectLogoutStatus);
    const previousLogoutStatus = usePrevious(logoutStatus);

    // States
    const [openDialog, setOpenDialog] = useState(false);
    const [isOnline, setOnline] = useState(true);

    /**
     * @type {[Socket|undefined, Function]} SocketState
     */
    const [socket, setSocket] = useState();

    const {VERSION_INTERVAL_DURATION_SEC} = config;
    useInterval(
        // poll for service version. Only for users who have a permission "execute:login:permanent"
        () => rights.includes(PERMISSION.EXECUTE_LOGIN_PERMANENT) && dispatch(fetchServiceVersionAction()),
        {delay: VERSION_INTERVAL_DURATION_SEC * 1000, immediately: true}
    );

    useEffect(() => {
        if (
            previousServiceVersion &&
            serviceVersion &&
            previousServiceVersion !== serviceVersion &&
            rights.includes(PERMISSION.EXECUTE_LOGIN_PERMANENT)
        ) {
            // The forceGet parameter only works with Firefox
            // @link https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
            // @ts-ignore
            window.location.reload(true);
        }
    }, [previousServiceVersion, serviceVersion, rights]);

    const onlineFunc = () => setOnline(true);
    const offlineFunc = () => setOnline(false);

    useEffect(() => {
        setOnline(navigator.onLine);

        window.addEventListener("online", onlineFunc);
        window.addEventListener("offline", offlineFunc);

        return () => {
            window.removeEventListener("online", onlineFunc);
            window.removeEventListener("offline", offlineFunc);
        };
    }, []);

    useEffect(() => {
        if (isPending(logoutStatus) && logoutStatus !== previousLogoutStatus) {
            auth.removeUser();
        }
    }, [logoutStatus, auth, previousLogoutStatus]);

    useEffect(() => {
        if (auth.isAuthenticated) {
            setToken(auth.user?.access_token);
            dispatch(authUser());
            dispatch(getUserStrategy());
        } else if (!auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth]);

    useEffect(() => {
        if (organizationId) {
            initWebSockets();
            dispatch(loadFeSettingsAction(organizationId));
            dispatch(loadStateAction());
            logger.info("App rendered", {organizationId, email});
        }
    }, [organizationId]);

    // If the access token was renewed, reconnect websocket with the new token
    // @link https://stackoverflow.com/questions/62198624/socket-io-refresh-tokens-before-reconnect
    // auth.events.addAccessTokenExpiring didn't work as expected.
    // @link https://github.com/authts/react-oidc-context#adding-event-listeners
    useEffect(() => {
        if (socket && auth.user?.access_token) {
            // @ts-ignore
            socket.auth.token = auth.user.access_token;
            socket.connect();
        }
    }, [auth.user, socket]);

    useEffect(() => {
        if (authError && authError.error) {
            setOpenDialog(true);
        } else if (openDialog) {
            setOpenDialog(false);
        }
    }, [authError]);

    useEffect(() => {
        initTranslations(pathTranslation, getLocalStorageItem("Account", "language") || theme.defaultLanguage);
    }, [theme]);

    useEffect(() => {
        if (socket) {
            // Event to refetch
            socket.on(WS_EVENTS.Refresh, (data) => {
                dispatch(loadStateAction());
                dispatch(setRefreshTrigger());
                if (data.event !== WS_ACTIONS.State) {
                    const {event, showBlockscreen} = data;
                    dispatch(setEvent({eventKey: event, showBlockscreen}));
                }
            });

            // All events related to the schedule flow
            socket.on(WS_EVENTS.Event, (data) => {
                const {showBlockscreen, blockedUntil, eventCreatedAt, event, eventId} = data;
                if (event) {
                    dispatch(setEvent({showBlockscreen, blockedUntil, eventCreatedAt, eventKey: event, eventId}));
                }
            });

            // Errors
            socket.on(WS_EVENTS.ErrorEvent, (data) => {
                const {showBlockscreen, event} = data;
                dispatch(setEvent({eventKey: event, showBlockscreen}));
                logger.error(`Websocket: ${WS_EVENTS.ErrorEvent}`, data, {organizationId, email});
            });

            socket.on(WS_EVENTS.connect_error, (err) => {
                if (err.message === "unauthorized") {
                    logger.warn("Websocket: connect_error, unauthorized: ", err);
                    socket.disconnect();
                } else {
                    // revert to classic upgrade
                    logger.info("Websocket: connect_error: ", err);
                    socket.io.opts.transports = ["websocket"];
                    socket.connect();
                }
            });
        }
    }, [socket]);

    /**
     * Init the WebSocket client
     */
    const initWebSockets = () => {
        const token = auth.user?.access_token;
        if (token) {
            setSocket(setupWebSocket(organizationId, token));
        } else {
            logger.warn("No token found for WebSocket");
        }
    };

    const handleLogout = async () => {
        setOpenDialog(false);
        const refreshToken = auth?.user?.refresh_token;
        if (refreshToken) {
            dispatch(logoutAction(refreshToken));
        } else {
            logger.warn("No refresh token found for logout");
        }
    };
    const muiTheme = useMemo(() => createTheme(theme), [theme]);

    if (auth.isLoading) return <Loading fullScreen />;
    if (auth.error) {
        new Error("Authentication failed");
    }
    if (!organizationId || isEmpty(theme)) return <Loading fullScreen />;

    return (
        <SecurityProvider permissions={rights}>
            <CacheProvider value={muiCache}>
                <TssCacheProvider value={tssCache}>
                    <ThemeProvider theme={muiTheme}>
                        <LocalizationProvider adapterLocale={currentLanguage} dateAdapter={AdapterLuxon}>
                            <DateProvider locale={currentLanguage} timezone={timezone}>
                                <SocketContext.Provider value={socket}>
                                    {openDialog && <InfoDialog open={openDialog} onClose={handleLogout} />}
                                    {!isOnline && <InfoDialog open={!isOnline} type={"connectionError"} />}
                                    <CssBaseline />
                                    <ErrorBoundary>
                                        <RouterProvider router={router} />
                                    </ErrorBoundary>
                                </SocketContext.Provider>
                            </DateProvider>
                        </LocalizationProvider>
                    </ThemeProvider>
                </TssCacheProvider>
            </CacheProvider>
        </SecurityProvider>
    );
};

export default App;
